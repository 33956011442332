import React from "react"
import Layout from "../components/layout"

const AboutPage = () => {
  return (
    <div>
      <Layout>
        <h1>About Me</h1>
        <p>This is my about page</p>
      </Layout>
    </div>
  )
}

export default AboutPage
